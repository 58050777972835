@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[type='search']:focus {
  --tw-ring-color: none;
}

input:hover {
  border-color: #3981f7;
}

input::placeholder,
select::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0aec0;
  font-weight: 100;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0aec0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0aec0;
}

#localize-widget {
  display: none;
  visibility: hidden;
}
@media (min-width: 1535px) {
  #localize-widget {
    display: block;
    visibility: visible;
    border-radius: 8px;
  }
  #localize-widget.localize-left-bottom {
    left: 1.25rem !important;
    margin-bottom: 75px !important;
    width: 167px;
  }
  #localize-widget-langs > a {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  #localize-active-lang {
    height: 48px;
    line-height: 48px;
    width: 100%;
    font-size: 400 !important;
  }
}
