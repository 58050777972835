.ant-spin .ant-spin-dot-item {
  background-color: white;
}

input::placeholder,
.ant-select-selector > span.ant-select-selection-placeholder {
  color: #a0aec0 !important;
  font-weight: 100;
}

.ant-picker-disabled,
.ant-picker .ant-picker-input > input[disabled],
.ant-select-disabled .ant-select-selector {
  background: rgb(245 245 245 / 1) !important;
  color: black !important;
}

.ant-pagination li.ant-pagination-item {
  border-right: 1px solid #e2e8f0;
  margin: 0;
  border-radius: 0;
}

.ant-pagination li.ant-pagination-item:nth-child(2) {
  border-left: 1px solid #e2e8f0;
}
.ant-pagination li.ant-pagination-item-active {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 1px solid #e4e4e7 !important;
  border-left: none !important;
  background-color: #f5f9ff;
}

ul.ant-pagination {
  background-color: #fff !important;
}
.ant-pagination-item-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

li.ant-pagination-next > button.ant-pagination-item-link,
li.ant-pagination-prev > button.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  margin-left: 5px;
  justify-content: center !important;
}

button.ant-pagination-item-link > span {
  color: #3981f7;
}

.ant-pagination-options-size-changer > div.ant-select-selector {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 1px solid #e4e4e7 !important;
  border-left: 1px solid #e4e4e7 !important;
  color: #3981f7;
  background-color: #fff !important;
}

.ant-pagination .ant-pagination-item > a {
  color: #3981f7;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: blueviolet;
}

.slick-dots li {
  background-color: rgb(147 186 251) !important;
}

.slick-dots li.slick-active button {
  background: rgb(29 78 216) !important;
}
.ant-picker-large {
  width: 100%;
}
